<template lang="pug">
a.FcwMobileAppStoreButton(
  :class="classes"
  :href="url"
  role="button"
  target="_blank"
)
  FImage(
    :src="badgeImageSrc"
    :alt="badgeImageAlt"
    loading="eager"
    corners="sharp"
  )
</template>

<style lang="stylus">
.FcwMobileAppStoreButton
  display inline-block

  &--playStore
    img
      height rem(52)
      margin rem(-6) 0
</style>

<script setup lang="ts">
export type AppStoreButtonType = 'play-store' | 'app-store';

export interface FcwMobileAppStoreButtonProps {
  /**
   * Set the store provider (Google Play Store or Apple’s App Store)
   */
  type?: AppStoreButtonType;
  /**
   * Localization of the badge
   */
  locale?: ISOLocale;
  /**
   * URL to download the app on the given store
   */
  url?: string;
}

const props = withDefaults(defineProps<FcwMobileAppStoreButtonProps>(), {
  type: 'app-store',
  locale: 'fr',
  url: '/',
});

const extensionMap = {
  'play-store': 'png',
  'app-store': 'svg',
};

const clientStore = useClientStore();
const { clientState } = storeToRefs(clientStore);

const { data: availableBadges } = await useFetch<string[]>(
  '/api/app/app-store-badges',
  {
    key: 'app-store-badges',
  }
);

const fallbackImage = computed(
  () =>
    `/images/badges/${props.type}/${clientState.value.defaultLocale}.${
      extensionMap[props.type]
    }`
);

const badgeImageSrc = ref<string>(fallbackImage.value);

watchImmediate([() => props.type, () => props.locale, availableBadges], () => {
  const currentLocaleBadgeUrl = `/images/badges/${props.type}/${props.locale}.${
    extensionMap[props.type]
  }`;

  badgeImageSrc.value = (availableBadges.value ?? []).some(badge =>
    currentLocaleBadgeUrl.endsWith(badge)
  )
    ? currentLocaleBadgeUrl
    : fallbackImage.value;
});

const { t } = useClientI18n();
const badgeImageAlt = computed(() =>
  t(`common__${props.type.replace(/-/, '_')}__badge_link_image__alt`)
);

const classes = computed<VueClasses>(() => ({
  'FcwMobileAppStoreButton--playStore': props.type === 'play-store',
}));
</script>
